<template>
  <v-dialog class="AddUserModal" v-model="modalOpen" max-width="630px">
    <v-card outlined>
      <v-card-title class="AddUserModal__title">
        <v-row>
          <v-col cols="auto" class="mr-auto"> Add new user </v-col>
          <v-col cols="auto">
            <v-btn icon @click="close">
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="AddUserModal__content">
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" autocomplete="off">
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="firstName"
                >
                  <v-text-field
                    label="First Name"
                    name="firstName"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.firstName"
                    :disbaled="addCustomerUserLoading"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="lastName"
                >
                  <v-text-field
                    label="Last Name"
                    name="lastName"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.lastName"
                    :disbaled="addCustomerUserLoading"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|email"
                  name="email"
                >
                  <v-text-field
                    label="Email"
                    name="email"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.email"
                    :disbaled="addCustomerUserLoading"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <v-text-field
                    label="Password"
                    name="password"
                    type="password"
                    flat
                    outlined
                    :hide-details="false"
                    :error="errors.length > 0"
                    :error-count="errors.length"
                    :error-messages="errors"
                    v-model="formData.password"
                    :disbaled="addCustomerUserLoading"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="formData.is_privileged"
                  label="Is this a privileged user?"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="addCustomerUserLoading"
                  :loading="addCustomerUserLoading"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: customerActions, mapGetters: customerGetters } =
  createNamespacedHelpers('customer');
const { mapGetters: notificationsGetters } =
  createNamespacedHelpers('notifications');

export default {
  name: 'AddUserModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {
        email: '',
        password: '',
        is_privileged: false,
        firstName: '',
        lastName: '',
      },
    };
  },
  methods: {
    ...customerActions(['addCustomerUser']),
    close() {
      this.modalOpen = false;
    },
    onSubmit() {
      this.addCustomerUser(this.formData);
    },
  },
  computed: {
    ...customerGetters({
      addCustomerUserLoading: 'ADD_CUSTOMER_USER_LOADING',
    }),
    ...notificationsGetters({ hasError: 'HAS_ERROR' }),
    modalOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    addCustomerUserLoading(newValue) {
      if (!newValue && !this.hasError) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './add-user-modal';
</style>
