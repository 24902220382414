<template>
  <div class="UserManagement">
    <v-row class="UserManagement__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="onAddUserModal">
          + Add User
        </v-btn>
      </v-col>

      <v-switch
        :input-value="customer.enabled2FAForAllUsers"
        label="Enable 2FA for all users"
        :disabled="!isUserCustomerAdmin() || changeCustomerInfoLoading"
        class="ml-4 text-caption pa-0"
        :loading="changeCustomerInfoLoading"
        @change="toggleCustomer2FA"
      ></v-switch>
    </v-row>

    <v-data-table
      class="Devices__data-table elevation-0"
      :items="customerUsers"
      :headers="headers"
      item-key="id"
      outlined
      :loading="customerUsersLoading"
    >
      <template #item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              @click.stop="resetUserPassword(item)"
              :disabled="!isUserCustomerAdmin()"
              v-bind="attrs"
              v-on="on"
              :loading="
                isAuthenticating && resettingPasswords.includes(item.email)
              "
            >
              <v-icon small> mdi-reload </v-icon>
            </v-btn>
          </template>
          <span class="text-caption">Reset User Password</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="error"
              @click.stop="removeUser(item)"
              :disabled="isCurrentUser(item) || !isUserCustomerAdmin()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <span class="text-caption">Delete User</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <delete-customer-user-prompt-modal
      v-model="showDeletePrompt"
      v-if="showDeletePrompt"
      :user-id="selectedUserId"
      :email="selectedUserEmail"
    />
    <add-user-modal v-model="showAddUserModal" v-if="showAddUserModal" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AddUserModal from './modals/add-user-modal/add-user-modal.vue';
import DeleteCustomerUserPromptModal from './modals/delete-customer-user-prompt-modal/delete-customer-user-prompt-modal.vue';

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers('customer');
const { mapActions: AuthActions, mapGetters: AuthGetters } =
  createNamespacedHelpers('auth');

export default {
  name: 'UserManagement',
  components: {
    AddUserModal,
    DeleteCustomerUserPromptModal,
  },
  data() {
    return {
      headers: [
        { text: 'First Name', value: 'firstName' },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'Is Admin', value: 'is_privileged' },
        { text: 'Is 2FA Enabled', value: 'enabled2FA' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      selectedUserId: '',
      selectedUserEmail: '',
      showAddUserModal: false,
      showDeletePrompt: false,
    };
  },
  methods: {
    ...customerActions(['getCustomerUsers', 'changeCustomerInfo']),
    ...AuthActions(['passwordReset']),
    removeUser({ email, id }) {
      this.selectedUserId = id;
      this.selectedUserEmail = email;
      this.showDeletePrompt = true;
    },
    isCurrentUser({ email }) {
      return email === this.user?.email;
    },
    isUserCustomerAdmin() {
      return this.user?.is_privileged;
    },
    onAddUserModal() {
      this.showAddUserModal = true;
    },
    resetUserPassword({ email }) {
      this.passwordReset({ email });
    },
    toggleCustomer2FA() {
      this.changeCustomerInfo({
        updated_type: 'customer',
        updated_data: {
          enabled2FAForAllUsers: !this.customer.enabled2FAForAllUsers,
        },
        destination_id: this.customer.customer_id,
      });
    },
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.getCustomerUsers();
    },
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      customerUsers: 'CUSTOMER_USERS',
      customerUsersLoading: 'CUSTOMER_USERS_LOADING',
      changeCustomerInfoLoading: 'CHANGE_CUSTOMER_INFO_LOADING',
    }),
    ...AuthGetters({
      isAuthenticating: 'IS_AUTHENTICATING',
      resettingPasswords: 'RESETTING_PASSWORDS',
    }),
  },
  mounted() {
    this.getCustomerUsers();
  },
};
</script>

<style lang="scss" scoped>
@import './user-management';
</style>
